import React, { useState, useReducer, useEffect } from "react";

import "./App.scss";
import { FaPencilAlt } from "react-icons/fa";
import { RxFramerLogo } from "react-icons/rx";
import Card from "./Components/Card";
import Topbar from "./Components/Topbar";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import Footer from "./Components/Footer";
import DownloadPopup from "./Components/DownloadPopup";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="loader">
      <PropagateLoader color={"#e7004d"} css={override} />
    </div>
  );
};

function App() {
  //const HOST = 'http://localhost:5001'
  const HOST = "http://143.198.58.92:5001";
  const [videoURL, setVideoURL] = useState("");
  const [history, setHistory] = useState([]);
  const [prompts, setPrompts] = useState();
  // const GetPrompts = async () => {
  //   try {
  //     const response = await fetch(`${HOST}/api/prompts`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     const data = await response.json();

  //     setPrompts(data);
  //     return data;
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  // useEffect(() => {
  //   GetPrompts();
  // }, []);

  const [getVideoSubsLoading, setGetVideoSubsLoading] = useState(false);
  const [getVideoSubsError, setGetVideoSubsError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [getResponse, setGetResponse] = useState();
  console.log("getResponse>>>>>>>>>>>>>>>", getResponse);
  console.log("process.env.REACT_APP_apiKey>>>>>>>>>>>>>>>", process.env.REACT_APP_apiKey);

  const GetVideoCover = async () => {
    setGetVideoSubsLoading(true);
    setGetVideoSubsError("");
    setSuccessMessage("");

    try {
      const response = await fetch(
        // `https://tc.tokbackup.com/getComments?video_url=${encodeURIComponent(
        `https://tt.tokbackup.com/fetchTikTokData?video=${encodeURIComponent(
          videoURL
        )}&get_transcript=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_apiKey,
            "Referer": document.referrer,
            "User-Agent": navigator.userAgent,
          },
        }
      );

      const data = await response.json();
      if (data) {
        setGetResponse(data);
        if (data.error) {
          console.error(data.message);
          setGetVideoSubsError(data.message);
        } else {
          // setSuccessMessage("Comments Fetch Successfully!");
          console.log("Comments Fetch Successfully!");
        }
      }

      if (data.error) {
        setSuccessMessage("");
        return null;
      }

      setHistory([data.subtitles]);
      return data.subtitles;
    } catch (error) {
      console.log(error.message);
    } finally {
      setGetVideoSubsLoading(false);
    }
  };

  const [question, setQuestion] = useState("");
  const [getAnswerLoading, setGetAnswerLoading] = useState(false);
  const [getAnswerError, setGetAnswerError] = useState(false);

  const GetAnswer = async (history) => {
    setGetAnswerLoading(true);
    setGetAnswerError("");
    console.log("sending history...");
    console.log(history);
    try {
      const response = await fetch(`${HOST}/api/tiktok/history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ history }),
      });

      const data = await response.json();

      if (data.error) {
        console.error(data.message);
        setGetAnswerError(data.message);
        return null;
      }

      setHistory((history) => [...history, data.answer]);
      setSuccessMessage("Answer generated successfully!");

      return data.answer;
    } catch (error) {
      console.error(error.message);
    } finally {
      setGetAnswerLoading(false);
    }
  };

  const [category, setCategory] = useState("");

  console.log({ category });

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.closest("#category-button")) {
        setCategory("");
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  console.log({ history });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        console.log("enter");
        if (!question.trim() || question?.length < 3) {
          setGetAnswerError("Please enter a valid question");
          console.log("Please enter a valid question " + question);
          return;
        }
        if (getAnswerLoading) {
          console.log("please wait for the ai to respond ");
          setGetAnswerError("Please wait for the ai to respond");
          return;
        }

        setHistory((history) => [...history, question]);
        setQuestion("");

        GetAnswer([...history, question]);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [question, getAnswerLoading]);
  console.log({ question });

  const CheckIfTiktokLinkIsValid = () => {
    if (!videoURL) return false;
    try {
      var id = videoURL?.match(/video\/.{19}/g)[0]?.replace("video/", "");

      if (id) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  //

  const [downloadPopupVisible, setDownloadPopupVisible] = useState(false);
  const [downloadCommentsLoading, setDownloadCommentsLoading] = useState(false);

  function handleSetUrlForVideo(url) {
    const questionMarkIndex = url.indexOf('?');
    // Slice the string to remove everything after the question mark
    const cleanedUrl = questionMarkIndex === -1 ? url : url.slice(0, questionMarkIndex);
    setVideoURL(cleanedUrl)
  }

  return (
    <div className="App ">
      <Topbar />
      <div className=" bg-gradient">
        <div style={{ paddingTop: "75px" }}>
          <img
            src="../fav.png"
            alt="logo"
            style={{
              fontSize: "75px",
              color: "#ccdae7",
              margin: "auto",
              width: "150px",
              // opacity: "0.5",
            }}
          />
          {/* <RxFramerLogo
            style={{
              fontSize: "75px",
              color: "#ccdae7",
              margin: "auto",
              opacity: "0.5",
            }}
          /> */}
        </div>
        <h1
          style={{
            color: "white",
            width: "90%",
            maxWidth: "720px",
            margin: "auto",
            marginTop: "30px",
            marginBottom: "-20px",
            color: "#ccdae7",
          }}
          className="sm:text-5xl text-4xl font-bold  leading-normal "
        >
          TikTok {" "}<span className="text-gradient__indigo-red px-0">Comment</span>{" "}
          Analyzer
        </h1>

        <h5
          className="sm:text-xl text-base font-light leading-normal mt-7"
          style={{ color: "rgb(204, 218, 231)" }}
        >
          Download all comments on any TikTok video
        </h5>

        <div
          className="flex justify-center mt-20"
          style={{ marginBottom: "-15px" }}
        >
          <div
            className="block p-6 rounded-lg  "
            style={{ width: "90%", maxWidth: "720px" }}
          >
            <div className="mb-3 flex items-center">
              <div class="p-1 w-full rounded-full bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] md:mr-2">
                <div class="flex flex-col sm:flex-row items-center font-semibold bg-gray-800 rounded-full w-full">
                  <div class="flex w-full items-center">
                    <input
                      type="text"
                      className="  block w-full px-10 py-4 text-base font-light 
              text-gray-900 shadow bg-white bg-clip-padding  
              rounded-3xl transition ease-in-out m-0  
               "
                      placeholder="Insert a Tiktok video..."
                      value={videoURL}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "50px",
                      }}
                      onChange={(e) => handleSetUrlForVideo(e.target.value)}
                    />{" "}
                  </div>
                </div>
              </div>

              <button
                type="button"
                className=" 
            inline-block px-6 py-2.5 bg-blue-600 text-white  
            font-medium text-xs leading-tight uppercase rounded-3xl 
            shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 
            focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 
            active:shadow-lg transition duration-150 ease-in-out  -ml-32
            
             bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] whitespace-nowrap
            "
                style={{
                  opacity: getVideoSubsLoading ? 0.5 : 1,
                  pointerEvents: getVideoSubsLoading ? "none" : "auto",
                  marginLeft: "-110px",
                  backgroundColor: "rgb(221, 175, 36)",
                }}
                onClick={() => {
                  if (!CheckIfTiktokLinkIsValid()) {
                    setSuccessMessage("");
                    setGetVideoSubsError(
                      "Please enter a valid tiktok video url"
                    );

                    return;
                  }
                  console.log("valid tiktok url !");
                  GetVideoCover();
                }}
              >
                START
              </button>
            </div>

            {getVideoSubsLoading && (
              <>
                <h2
                  style={{
                    marginTop: "100px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Loading Data... */}
                  <Loader />
                </h2>
                <h3
                  className="animate-pulse"
                  style={{
                    color: "white",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Getting Video Info....
                  {/* Comments Downloading! This can take anywhere from 20secs-5mins
                  depending on how many comments there are */}
                </h3>
              </>
            )}

            {!getVideoSubsLoading && getVideoSubsError && (
              <div
                className="text-white text-xs mt-4 bg-red-500 rounded-2xl px-4 py-1"
                style={{ width: "fit-content", margin: "auto" }}
              >
                {getVideoSubsError}
              </div>
            )}

            {!getVideoSubsLoading && successMessage && (
              <div
                className="text-white text-xs mt-4 bg-green-500 rounded-2xl px-4 py-1"
                style={{ width: "fit-content", margin: "auto" }}
              >
                {successMessage}
              </div>
            )}

            {/* model */}
            {downloadPopupVisible && (
              <DownloadPopup
                hideDownloadPopup={() => setDownloadPopupVisible(false)}
                loading={downloadCommentsLoading}
              />
            )}
          </div>
        </div>
        {/* {
          <div
            key={prompts}
            className="animate__animated animate__fadeIn"
            style={{ minHeight: "50px", opacity: prompts ? "1" : "0" }}
          ></div>
        } */}
        {category && (
          <div
            key={category}
            className="block p-6 rounded-lg shadow-lg bg-white animate__animated animate__fadeIn animate__faster "
            style={{
              width: "fit-content",
              maxWidth: "500px",
              background: "#534898",
              margin: "auto",
              marginTop: "20px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "10",
            }}
          >
            {prompts[category].map((prompt, index) => {
              return (
                <div key={index} className="flex justify-start my-6">
                  <button
                    onClick={() => {
                      if (!history?.length) {
                        console.log("Please input a Tiktok video first! ");
                        setGetVideoSubsError(
                          "Please input a Tiktok video first!"
                        );
                        return;
                      }
                      if (getAnswerLoading) {
                        console.log("please wait for the ai to respond ");
                        setGetAnswerError("Please wait for the ai to respond");
                        return;
                      }
                      setHistory((history) => [...history, prompt.aiPrompt]);
                      setQuestion("");
                      GetAnswer([...history, prompt.aiPrompt]);
                    }}
                    type="button"
                    style={{ background: "#6559ac", width: "400px" }}
                    className="min-w-[12rem] h-12   relative w-full rounded text-white py-2 px-8 text-left shadow-md focus:outline-none 
              focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
              focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm cursor-pointer 
              bg-neutral-500"
                  >
                    <div class="flex gap-2  items-center justify-start ">
                      <div class="flex-shrink-0 h-4 w-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                          ></path>
                        </svg>
                      </div>
                      <span
                        class="block text-left "
                        style={{ fontSize: "14px" }}
                      >
                        {prompt.promptTitle}
                      </span>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div
        className="flex justify-center my-12 mb-2"
        style={{ marginTop: "-180px" }}
      ></div>

      {!getVideoSubsLoading && getResponse ? (
        <Card
          data={getResponse}
          loading={getVideoSubsLoading}
          videoURL={videoURL}
          successMessage={successMessage}
          getVideoSubsError={getVideoSubsError}
          downloadPopupVisible={downloadPopupVisible}
          setDownloadPopupVisible={setDownloadPopupVisible}
          downloadCommentsLoading={downloadCommentsLoading}
          setDownloadCommentsLoading={setDownloadCommentsLoading}
        />
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
}

export default App;
